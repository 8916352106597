// It is important to import with a trailing slash here
import { Buffer } from 'buffer/';

const escape = (str: string): string =>
  str.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');

const unescape = (str: string): string => {
  const m = str.length % 4;
  return str
    .replace(/-/g, '+')
    .replace(/_/g, '/')
    .padEnd(str.length + (m === 0 ? 0 : 4 - m), '=');
};

export const stringToBase64url = (str: string): string =>
  escape(Buffer.from(str).toString('base64'));

export const base64urlToString = (str: string): string =>
  Buffer.from(unescape(str), 'base64').toString('utf-8');
