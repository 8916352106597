export enum UTMTag {
  Source = 'utm_source',
  Medium = 'utm_medium',
  Campaign = 'utm_campaign',
  Content = 'utm_content',
  Term = 'utm_term',
}

export type UTMObject = Partial<Record<UTMTag, string>>;

export interface LeadContactDetails {
  email: string | null;
}

export interface LeadSource {
  firstVisitedUrl?: string | null;
  lastTrackedUTM?: UTMObject | null;
}

export interface Lead {
  contactDetails: LeadContactDetails;
  source: LeadSource;
}
