/* eslint-disable @next/next/no-html-link-for-pages */
import React from 'react';
import { Trans, useTranslation } from 'next-i18next';
import HeaderMobileMenuCollapsibleItem from '../header-mobile-menu-collapsible-item/header-mobile-menu-collapsible-item';
import useLocalizedPath from '../../hooks/use-localized-path/use-localized-path';

type HeaderMobileMenuProps = {};

const HeaderMobileMenu: React.FC<HeaderMobileMenuProps> = () => {
  const { t } = useTranslation();

  const getLocalizedPath = useLocalizedPath();

  return (
    <div className="header__mobile-menu">
      <nav className="header__mobile-menu_nav">
        <div className="header__mobile-menu_group-label">
          {t('LAYOUTS.LANDING.HEADER.GROUP_LABEL.EXPLORE')}
        </div>
        <div className="header__mobile-menu_nav-container">
          <HeaderMobileMenuCollapsibleItem label={t('LAYOUTS.LANDING.HEADER.SEXUAL_HEALTH')}>
            <a
              href={getLocalizedPath('/erectile-dysfunction')}
              className="header__mobile-menu_banner"
              style={{ backgroundImage: 'url(/img/header-mobile-menu-banner.png)' }}
            >
              <strong className="header__mobile-menu_banner-text">
                <Trans
                  i18nKey="LAYOUTS.LANDING.HEADER.SEXUAL_HEALTH.BANNER_TEXT"
                  components={[<b key={0} />]}
                />
              </strong>
            </a>
            <a
              href={getLocalizedPath('/erectile-dysfunction')}
              className="header__mobile-menu_link header__mobile-menu_link--secondary"
            >
              {t('LAYOUTS.LANDING.HEADER.LINKS.ERECTILE_DYSFUNCTION')}
              <b
                className="header__mobile-menu_link-icon"
                style={{ backgroundImage: 'url(/img/icons/arrow-right-white.svg)' }}
              />
            </a>
            <a
              href={getLocalizedPath('/premature-ejaculation')}
              className="header__mobile-menu_link header__mobile-menu_link--secondary"
            >
              {t('LAYOUTS.LANDING.HEADER.LINKS.PREMATURE_EJACULATION')}
              <b
                className="header__mobile-menu_link-icon"
                style={{ backgroundImage: 'url(/img/icons/arrow-right-white.svg)' }}
              />
            </a>
          </HeaderMobileMenuCollapsibleItem>
          <a href={getLocalizedPath('/weight-loss')} className="header__mobile-menu_link">
            {t('LAYOUTS.LANDING.HEADER.LINKS.WEIGHT_LOSS')}
            <b
              className="header__mobile-menu_link-icon"
              style={{ backgroundImage: 'url(/img/icons/arrow-right-white.svg)' }}
            />
          </a>
          <a href={getLocalizedPath('/hair-loss')} className="header__mobile-menu_link">
            {t('LAYOUTS.LANDING.HEADER.LINKS.HAIR_LOSS')}
            <b
              className="header__mobile-menu_link-icon"
              style={{ backgroundImage: 'url(/img/icons/arrow-right-white.svg)' }}
            />
          </a>
          <a href={getLocalizedPath('/skincare')} className="header__mobile-menu_link">
            {t('LAYOUTS.LANDING.HEADER.LINKS.SKINCARE')}
            <b
              className="header__mobile-menu_link-icon"
              style={{ backgroundImage: 'url(/img/icons/arrow-right-white.svg)' }}
            />
          </a>
          <span className="header__mobile-menu_link">
            {t('LAYOUTS.LANDING.HEADER.LINKS.SLEEP')}
            <b className="header__mobile-menu_label">
              {t('LAYOUTS.LANDING.HEADER.COMING_SOON_LABEL.TEXT')}
            </b>
          </span>
          <span className="header__mobile-menu_link">
            {t('LAYOUTS.LANDING.HEADER.LINKS.TESTOSTERONE')}
            <b className="header__mobile-menu_label">
              {t('LAYOUTS.LANDING.HEADER.COMING_SOON_LABEL.TEXT')}
            </b>
          </span>
          <span className="header__mobile-menu_link">
            {t('LAYOUTS.LANDING.HEADER.LINKS.BLOOD_TEST')}
            <b className="header__mobile-menu_label">
              {t('LAYOUTS.LANDING.HEADER.COMING_SOON_LABEL.TEXT')}
            </b>
          </span>
        </div>
      </nav>
      <div className="header__mobile-menu_separator" />
      <nav className="header__mobile-menu_nav header__mobile-menu_nav--secondary">
        <div className="header__mobile-menu_group-label">
          {t('LAYOUTS.LANDING.HEADER.GROUP_LABEL.ABOUT')}
        </div>
        <div className="header__mobile-menu_nav-container">
          <a href={getLocalizedPath('/about')}>{t('LAYOUTS.LANDING.HEADER.LINKS.ABOUT')}</a>
          {/* <a href={getLocalizedPath('/facts-and-advice')}>{t('LAYOUTS.LANDING.HEADER.LINKS.FACTS_AND_ADVICE')}</a> */}
        </div>
      </nav>
    </div>
  );
};

export default HeaderMobileMenu;
