import React from 'react';

type HeaderMobileMenuCollapsibleItemProps = {
  label: string;
  children: React.ReactNode;
};

const HeaderMobileMenuCollapsibleItem: React.FC<HeaderMobileMenuCollapsibleItemProps> = ({
  label,
  children,
}) => {
  const [isCollapsed, setIsCollapsed] = React.useState(true);

  return (
    <li>
      <a
        className="header__mobile-menu_link"
        href="#"
        onClick={e => {
          e.preventDefault();
          setIsCollapsed(!isCollapsed);
        }}
      >
        {label}
        <b
          className="header__mobile-menu_link-icon"
          style={{
            backgroundImage: 'url(/img/icons/chevron-down-white-new.svg)',
            transform: isCollapsed ? 'rotate(90deg)' : 'rotate(270deg)',
          }}
        />
      </a>
      {!isCollapsed && <div className="header__mobile-menu_collapsible-content">{children}</div>}
    </li>
  );
};

export default HeaderMobileMenuCollapsibleItem;
