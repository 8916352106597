import React from 'react';

const ExternalWidgetHidden = () => (
  <style jsx global>{`
    #imbox-container {
      display: none !important;
    }
  `}</style>
);

export default ExternalWidgetHidden;
