import Cookies from 'js-cookie';
import * as Sentry from '@sentry/nextjs';
import { LeadSource } from '../../model/entities/shared/lead';
import { base64urlToString, stringToBase64url } from '../base64-utils/base64-utils';
import { LEAD_SOURCE_COOKIE_NAME } from '../../model/constants';
import getCookieAttributes from '../get-cookie-attributes/get-cookie-attributes';

export const readLeadSource = (): LeadSource => {
  let leadSource: LeadSource = {};
  const cookieString = Cookies.get(LEAD_SOURCE_COOKIE_NAME);
  if (cookieString) {
    try {
      leadSource = JSON.parse(base64urlToString(cookieString));
    } catch (e) {
      Sentry.captureException(new Error('Could not parse the lead analytics info cookie'), {
        extra: {
          cookieString,
        },
      });
    }
  }
  return leadSource;
};

export const writeLeadSource = (source: LeadSource) => {
  // TODO: Set cookie on server side to increase 7days cookie limit
  Cookies.set(
    LEAD_SOURCE_COOKIE_NAME,
    stringToBase64url(JSON.stringify(source)),
    getCookieAttributes(365)
  );
};
