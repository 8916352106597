import { intlFormat } from 'date-fns';

export enum DateFormatterStyle {
  Default,
  Date,
  Messages,
  Datetime,
  Time,
}

const getDateFormatOptions = (style: DateFormatterStyle): Intl.DateTimeFormatOptions => {
  switch (style) {
    case DateFormatterStyle.Datetime:
      return {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false,
      };
    case DateFormatterStyle.Time:
      return {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      };
    case DateFormatterStyle.Date:
      return {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      };
    case DateFormatterStyle.Messages:
      return {
        month: 'short',
        day: 'numeric',
        weekday: 'short',
      };
    case DateFormatterStyle.Default:
    default:
      return {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      };
  }
};

const formatDateLocalized = (date: Date, style: DateFormatterStyle, locale: string) => {
  return intlFormat(date, getDateFormatOptions(style), {
    locale,
  });
};

export default formatDateLocalized;
