import React from 'react';
import { useRouter } from 'next/router';
import buildLocalizedPath from '../../utils/build-localized-path/build-localized-path';

const useLocalizedPath = () => {
  const router = useRouter();

  return React.useCallback(
    (path: string) => {
      return buildLocalizedPath({
        path,
        locale: router.locale,
        defaultLocale: router.defaultLocale,
      });
    },
    [router]
  );
};

export default useLocalizedPath;
