/* eslint-disable @next/next/no-html-link-for-pages */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import Link from 'next/link';
import HeaderMobileMenu from '../header-mobile-menu/header-mobile-menu.component';
import ReferralProgramDiscountAppliedBanner from '../referral-program-discount-applied-banner/referral-program-discount-applied-banner.component';
import useLocalizedPath from '../../hooks/use-localized-path/use-localized-path';

type HeaderProps = {
  isDarkMode?: boolean;
  isStatic?: boolean;
  staticHeaderBackgroundColor?: string;
};

const LOGO_PATH = '/img/header-logo-new.svg';

const Header: React.FC<HeaderProps> = ({ staticHeaderBackgroundColor, isDarkMode, isStatic }) => {
  const { t } = useTranslation();
  const router = useRouter();

  const [isMobileMenuVisible, setIsMobileMenuVisible] = useState<boolean>(false);
  const [scrollDirection, setScrollDirection] = React.useState<number | null>();

  const getLocalizedPath = useLocalizedPath();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      let lastScrollY = window.scrollY;

      const onScroll = () => {
        const { scrollY } = window;
        const direction = scrollY > lastScrollY ? 1 : -1;
        if (scrollY - lastScrollY > 10 || scrollY - lastScrollY < -10 || scrollY < 100) {
          setScrollDirection(scrollY > 0 && !isStatic ? direction : null);
        }
        lastScrollY = scrollY > 0 ? scrollY : 0;
      };

      window.addEventListener('scroll', onScroll);
      return () => {
        window.removeEventListener('scroll', onScroll);
      };
    }

    return undefined;
  }, [setScrollDirection, isStatic]);

  useEffect(() => {
    const handleRouteChange = () => {
      setIsMobileMenuVisible(false);
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => router.events.off('routeChangeComplete', handleRouteChange);
  }, [router]);

  useEffect(() => {
    if (isMobileMenuVisible) {
      const targetElement = document.querySelector('.header__mobile-menu');
      if (targetElement) {
        disableBodyScroll(targetElement);
      }
    } else {
      clearAllBodyScrollLocks();
    }
  }, [isMobileMenuVisible]);

  const isUpScroll = scrollDirection === -1;
  const isDownScroll = scrollDirection === 1;
  const isHeaderDarkMode = isDarkMode || isUpScroll || isMobileMenuVisible;

  return (
    <>
      <header
        className={`header ${isHeaderDarkMode ? 'header--dark-mode' : ''} ${
          isUpScroll ? 'header--up-scroll' : ''
        } ${isDownScroll ? 'header--down-scroll' : ''}
         ${isMobileMenuVisible ? 'header--mobile-menu-visible' : ''}`}
        style={
          staticHeaderBackgroundColor != null
            ? { backgroundColor: staticHeaderBackgroundColor }
            : undefined
        }
      >
        <ReferralProgramDiscountAppliedBanner />
        <div className="header__content">
          <div className="header__logo">
            <a href={getLocalizedPath('/')}>
              <img src={LOGO_PATH} alt="" style={{ cursor: 'pointer' }} />
            </a>
          </div>
          <div className="header__desktop-menu">
            <a href={getLocalizedPath('/erectile-dysfunction')}>
              {t('LAYOUTS.LANDING.HEADER.LINKS.ERECTILE_DYSFUNCTION')}
            </a>
            <a href={getLocalizedPath('/weight-loss')}>
              {t('LAYOUTS.LANDING.HEADER.LINKS.WEIGHT_LOSS')}
            </a>
            <a href={getLocalizedPath('/premature-ejaculation')}>
              {t('LAYOUTS.LANDING.HEADER.LINKS.PREMATURE_EJACULATION')}
            </a>
            <a href={getLocalizedPath('/hair-loss')}>
              {t('LAYOUTS.LANDING.HEADER.LINKS.HAIR_LOSS')}
            </a>
            <a href={getLocalizedPath('/skincare')}>{t('LAYOUTS.LANDING.HEADER.LINKS.SKINCARE')}</a>
            {/* <a href={getLocalizedPath('/facts-and-advice')}>{t('LAYOUTS.LANDING.HEADER.LINKS.FACTS_AND_ADVICE')}</a> */}
            <a href={getLocalizedPath('/about')}>{t('LAYOUTS.LANDING.HEADER.LINKS.ABOUT')}</a>
          </div>
          <div className="header__logo onboarding-header-logo">
            <a
              onClick={e => {
                e.preventDefault();
                if (window.confirm(t('LAYOUTS.LANDING.HEADER.SHOW_HOME_PAGE_CONFIRMATION.TEXT'))) {
                  document.location.assign(getLocalizedPath('/'));
                }
              }}
            >
              <img src={LOGO_PATH} alt="" />
            </a>
          </div>
          <div className="header__profile-btn-container">
            <Link href="/profile">
              <a
                className={`header__profile-btn ${
                  isHeaderDarkMode ? 'header__profile-btn--black' : ''
                }`}
              >
                {t('LAYOUTS.LANDING.HEADER.LINKS.DESKTOP.MY_PROFILE')}
              </a>
            </Link>
          </div>
        </div>
      </header>
      <div
        className={`header__btn-menu ${isMobileMenuVisible ? 'active' : ''} ${
          isUpScroll ? 'header__btn-menu--up-scroll' : ''
        } ${isDownScroll ? 'header__btn-menu--down-scroll' : ''} ${
          isHeaderDarkMode ? 'header--dark-mode' : ''
        }`}
        onClick={() => setIsMobileMenuVisible(!isMobileMenuVisible)}
      >
        <span>&nbsp;</span>
        <span>&nbsp;</span>
        <span>&nbsp;</span>
      </div>
      <img src="/img/icons/close.svg" style={{ display: 'none' }} />
      {isMobileMenuVisible && <HeaderMobileMenu />}
    </>
  );
};

export default Header;
