import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Cookies, { CookieAttributes } from 'js-cookie';
import { RootState } from '../../model/slices/root/root.state';
import { REFERRAL_CODE_COOKIE_NAME } from '../../model/constants';
import { actions } from '../../model/slices/app/app.slice';
import getCookieAttributes from '../../utils/get-cookie-attributes/get-cookie-attributes';

const useReferralCode = (): [string | null | undefined, (code: string | null) => void, boolean] => {
  const dispatch = useDispatch();
  const referralCodeState = useSelector((state: RootState) => state.app.referralCode);

  React.useEffect(() => {
    if (referralCodeState === undefined) {
      const referralCodeCookieValue = Cookies.get(REFERRAL_CODE_COOKIE_NAME);
      dispatch(actions.setReferralCode(referralCodeCookieValue || null));
    }
  }, [dispatch, referralCodeState]);

  const setReferralCode = React.useCallback(
    (referralCode: string | null) => {
      const cookieAttributes: CookieAttributes = getCookieAttributes(365);
      if (referralCode != null) {
        Cookies.set(REFERRAL_CODE_COOKIE_NAME, referralCode, cookieAttributes);
      } else {
        Cookies.remove(REFERRAL_CODE_COOKIE_NAME, cookieAttributes);
      }
      dispatch(actions.setReferralCode(referralCode));
    },
    [dispatch]
  );

  return [referralCodeState, setReferralCode, referralCodeState !== undefined];
};

export default useReferralCode;
