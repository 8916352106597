import axios from 'axios';
import { ErrorResponse } from '../../model/types/error-response';

const getAydApiErrorResponse = (e: unknown) => {
  if (!axios.isAxiosError(e)) {
    return null;
  }
  const response = e.response?.data;
  if (
    typeof response === 'object' &&
    typeof response.error === 'object' &&
    typeof response.error.message === 'string' &&
    typeof response.error.code === 'number'
  ) {
    return response as ErrorResponse;
  }
  return null;
};

export default getAydApiErrorResponse;
