import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import useApiClient from './use-api-client';
import MeService from '../api/services/me.service';
import { ME_QUERY_KEY } from './use-me-query';

const useApplyReferralCodeMutation = (): UseMutationResult<
  void,
  unknown,
  { referralCode: string }
> => {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();

  return useMutation(vars => new MeService(apiClient).applyReferralCode(vars.referralCode), {
    onSuccess: () => {
      queryClient.invalidateQueries(ME_QUERY_KEY).catch(() => {});
    },
  });
};

export default useApplyReferralCodeMutation;
