import React, { useRef } from 'react';
import 'viewerjs/dist/viewer.css';
import Viewer from 'viewerjs';
import { useDispatch, useSelector } from 'react-redux';
import { fromUnixTime } from 'date-fns';
import { useTranslation } from 'next-i18next';
import { RootState } from '../../model/slices/root/root.state';
import { actions } from '../../model/slices/app/app.slice';
import formatDateLocalized, {
  DateFormatterStyle,
} from '../../utils/format-date-localized/format-date-localized';

const CONTAINER_ID = 'imageViewer';

const ImageViewer = () => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();

  const imageViewerParams = useSelector((root: RootState) => root.app.imageViewerParams);
  const gallery = useRef<Viewer | null>(null);
  const [currentSlide, setCurrentSlide] = React.useState(imageViewerParams?.initialViewIndex || 0);

  React.useEffect(() => {
    const container = document.getElementById(CONTAINER_ID);
    if (container) {
      gallery.current = new Viewer(container, {
        initialViewIndex: imageViewerParams?.initialViewIndex || 0,
        toolbar: false,
        title: false,
        inline: true,
        movable: true,
        fullscreen: false,
        button: false,
        backdrop: false,
        toggleOnDblclick: false,
        minZoomRatio: 0.2,
        navbar: imageViewerParams != null ? imageViewerParams?.data.length > 1 : false,
        view(event: CustomEvent) {
          setCurrentSlide(event.detail.index);
        },
      });
    }
    if (imageViewerParams != null && gallery.current) {
      gallery.current.show();
    }
    return () => {
      if (gallery.current) {
        gallery.current.destroy();
      }
    };
  }, [dispatch, imageViewerParams]);

  const onClose = React.useCallback(() => {
    dispatch(actions.setImageViewerParams(null));
    gallery.current?.destroy();
  }, [dispatch]);

  React.useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      if (gallery.current) {
        if (event.key === 'Escape') {
          onClose();
        }
        if (event.key === 'ArrowRight') {
          gallery.current.next();
        }
        if (event.key === 'ArrowLeft') {
          gallery.current.prev();
        }
      }
    };
    const onDoubleClick = () => {
      if (gallery.current) {
        // @ts-ignore;
        const { imageData } = gallery.current;
        if (imageData.ratio >= 1) {
          gallery.current?.reset();
        } else {
          gallery.current?.zoomTo(1);
        }
      }
    };
    if (imageViewerParams != null) {
      document.addEventListener('keydown', onKeyDown);
      document.addEventListener('dblclick', onDoubleClick);
    } else {
      document.removeEventListener('keydown', onKeyDown);
      document.removeEventListener('dblclick', onDoubleClick);
    }
    return () => {
      document.removeEventListener('keydown', onKeyDown);
      document.removeEventListener('dblclick', onDoubleClick);
    };
  }, [imageViewerParams, onClose]);

  const slideInfo = imageViewerParams != null ? imageViewerParams.data[currentSlide] : null;

  return (
    <div className="image-viewer" style={{ display: imageViewerParams != null ? 'block' : 'none' }}>
      <div className="image-viewer__header">
        <div className="image-viewer__header_close" onClick={onClose} />
        <div>
          {slideInfo?.author != null && (
            <div className="image-viewer__header_author">{slideInfo.author}</div>
          )}
          {slideInfo?.createdAt != null && (
            <div className="image-viewer__header_date">
              {formatDateLocalized(
                fromUnixTime(slideInfo.createdAt),
                DateFormatterStyle.Default,
                i18n?.resolvedLanguage
              )}
            </div>
          )}
        </div>
      </div>
      <div id={CONTAINER_ID}>
        {imageViewerParams &&
          imageViewerParams.data.map(slide => <img alt="" key={slide.url} src={slide.url} />)}
      </div>
    </div>
  );
};

export default ImageViewer;
