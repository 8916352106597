import React from 'react';

type FooterLinksBlockProps = {
  title: string;
  children: any;
};

const FooterLinksBlock: React.FC<FooterLinksBlockProps> = ({ title, children }) => {
  const [isVisible, setIsVisible] = React.useState(false);

  return (
    <div
      className={`footer-new__main_item ${
        isVisible ? 'footer-new__main_item--mobile-visible' : ''
      }`}
    >
      <div className="footer-new__group-title" onClick={() => setIsVisible(!isVisible)}>
        <span>{title}</span>
        <b className="footer-new__group-title_mobile-icon" />
      </div>
      <div className="footer-new__main_item-content">{children}</div>
    </div>
  );
};

export default FooterLinksBlock;
