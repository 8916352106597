export enum ErrorCode {
  Generic = 1001,
  PhoneVerificationMaxSendAttemptsReached = 3001,
  PhoneVerificationMaxCheckAttemptsReached = 3002,
  PhoneVerificationLandlineIsNotSupported = 3003,
  PhoneVerificationCheckExpired = 3004,
  EmailAlreadyExists = 4001,
  CaseReportCompletionCheckFailed = 5001,
  MessageUpdatePatientReadControlFailed = 6001,
  MessageUpdateTicketCheckFailed = 6002,
  ReferralCodeDoesNotExist = 7001,
  ReferralCodeIsAlreadyApplied = 7002,
  ReferralCodeIsInvalid = 7003,
}

export interface ErrorResponse {
  error: {
    message: string;
    code: ErrorCode;
    stack?: string;
  };
}
