import React from 'react';
import { useTranslation } from 'next-i18next';
import { toastr } from 'react-redux-toastr';
import useMeQuery from '../../model/hooks/use-me-query';
import useReferralCode from '../../hooks/use-referral-code/use-referral-code';
import useApplyReferralCodeMutation from '../../model/hooks/use-apply-referral-code-mutation';
import getAydApiErrorResponse from '../../utils/get-ayd-api-error-response/get-ayd-api-error-response';
import { ErrorCode } from '../../model/types/error-response';

const ReferralProgramDiscountAppliedBanner = () => {
  const { t } = useTranslation();
  const { data: user } = useMeQuery();
  const [referralCode, setReferralCode] = useReferralCode();
  const { mutateAsync: applyReferralCode } = useApplyReferralCodeMutation();

  const applyReferralCodeToUser = React.useCallback(
    async (code: string) => {
      try {
        await applyReferralCode({ referralCode: code });
        setReferralCode(null);
      } catch (e) {
        const errorResponse = getAydApiErrorResponse(e);

        switch (errorResponse?.error.code) {
          case ErrorCode.ReferralCodeDoesNotExist:
          case ErrorCode.ReferralCodeIsInvalid:
          case ErrorCode.ReferralCodeIsAlreadyApplied:
            setReferralCode(null);
            break;
          default:
            break;
        }

        toastr.error(
          t('REFERRAL_PROGRAM_DISCOUNT_APPLIED_BANNER.APPLY_CODE.ERROR.TITLE'),
          t('REFERRAL_PROGRAM_DISCOUNT_APPLIED_BANNER.APPLY_CODE.ERROR.DESCRIPTION')
        );
      }
    },
    [applyReferralCode, t, setReferralCode]
  );

  React.useEffect(() => {
    if (user && referralCode) {
      if (user.referredBy == null) {
        applyReferralCodeToUser(referralCode).catch(() => {});
      } else {
        setReferralCode(null);
      }
    }
  }, [applyReferralCodeToUser, user, referralCode, setReferralCode]);

  const isReferralProgramApplicable = user?.isApplicableForReferralProgram ?? !!referralCode;

  React.useEffect(() => {
    if (typeof document !== 'undefined') {
      if (isReferralProgramApplicable) {
        document.body.classList.add('referral-program-discount-applied');
      } else {
        document.body.classList.remove('referral-program-discount-applied');
      }
    }
  }, [isReferralProgramApplicable]);

  if (!isReferralProgramApplicable) {
    return null;
  }

  return (
    <div className="referral-program-discount-applied-banner">
      <div className="referral-program-discount-applied-banner__description">
        {t('REFERRAL_PROGRAM_DISCOUNT_APPLIED_BANNER.DESCRIPTION')}
      </div>
    </div>
  );
};

export default ReferralProgramDiscountAppliedBanner;
